import merge from '~lodash.mergewith'
import { init } from '~@sentry/vue'
import * as CoreSdk from '~@sentry/core'
import * as BrowserSdk from '~@sentry/browser-sdk'
import { ExtraErrorData, ReportingObserver, RewriteFrames } from '~@sentry/integrations'

export { init }
export const SentrySdk = { ...CoreSdk, ...BrowserSdk }

export function getConfig (ctx) {
  /* eslint-disable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  const config = {
    dsn:"https:\u002F\u002F6e6f8454b31045009aaee37911394a02@sentry.io\u002F1424353",
    environment:"production",
    clientIntegrations:{"Vue":{"attachProps":true}},
    serverIntegrations:{"Vue":{"attachProps":true}},
    release:"1.0.2740",
    ignoreErrors:["getReadModeConfig","getReadModeExtract","getReadModeRender","ReportingObserver [deprecation]: Unload event listeners are deprecated and will be removed."],
  }

  config.integrations = [
    new ExtraErrorData(),
    new ReportingObserver(),
    new RewriteFrames(),
  ]

  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  return config
}
